/* eslint-disable import/no-anonymous-default-export */
import { API, Auth } from "aws-amplify";
import { Button, Form, Input, Icon } from 'semantic-ui-react'
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { useTranslation } from 'react-i18next';
import config from "../../../config";
import '../../../i18n';

import './crd_reset.css';



export default function({ var_requestresult, var_requestid, var_request, set_request, set_reset_step }){

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();
    const { t } = useTranslation();

    const [ var_login, set_login ] = useState([]);

    const [var_validatepassword_length, set_validatepassword_length] = useState(false);
    const [var_validatepassword_lowercase, set_validatepassword_lowercase] = useState(false);
    const [var_validatepassword_uppercase, set_validatepassword_uppercase] = useState(false);
    const [var_validatepassword_number, set_validatepassword_number] = useState(false);
    const [var_validatepassword_specialcharacter, set_validatepassword_specialcharacter] = useState(false);

    const [ var_processing, set_processing ] = useState(false);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_passwordShown, set_passwordShown ] = useState(false);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_validatepassword_length && var_validatepassword_lowercase && var_validatepassword_uppercase && var_validatepassword_number && var_validatepassword_specialcharacter) {
            set_ready(true)
        } else {
            set_ready(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_validatepassword_length, var_validatepassword_lowercase, var_validatepassword_uppercase, var_validatepassword_number, var_validatepassword_specialcharacter]);

    useEffect(() => {
        validatenewpassword();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_login.newpassword]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function submit_password(){
        if(var_ready) {

            set_processing(true);

            try {

                //  create new account
                let passwordreset = await API_put_passwordreset();

                if(passwordreset === 'FAIL'){
                    set_reset_step('RESETFAIL');
                }else {

                    //  sign in to new account
                    await Auth.signIn(passwordreset, var_login.newpassword);

                    //  get cognito user details
                    let AWSuser = await Auth.currentUserInfo();

                    //  get TerraHub user details
                    let result = await API_get_authentication();

                    //check if account has been archived
                    if (result === 'NO ACTIVE ACCOUNT'){
                        set_processing(false);
                        alert('NO ACTIVE ACCOUNT');
                    }

                    let authentication = {};
                    authentication['authenticated'] = true;
                    authentication['id'] = result.id;
                    authentication['individual_type'] = result.individual_type;
                    authentication['actingorganization'] = result.organization;
                    authentication['actingorganization_id'] = result.organization_id;
                    authentication['actingpermission_id'] = result.permission_id;
                    authentication['cognito_id'] = AWSuser.username;
                    authentication['firstname'] = result.firstname;
                    authentication['lastname'] = result.lastname;
                    authentication['userid'] = result.userid;
                    authentication['email'] = result.email;
                    authentication['organization'] = result.organization;
                    authentication['organization_id'] = result.organization_id;
                    authentication['permission_id'] = result.permission_id;
                    authentication['org_host'] = result.org_host;
                    authentication['language'] = 'en';
                    authentication['access'] = result.access;
                                                      
                    sessionStorage.setItem('authentication',JSON.stringify(authentication));

                    history.push('/individuals')

                }

            }catch (error) {
                set_processing(false);
            }
        }
    }

    
    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_authentication(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-authentication');
    }

    function API_put_passwordreset(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('abcrc-admin-api', '/put-passwordreset/'+ var_requestid, { body: var_login });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function validatenewpassword(){

        if(var_login.newpassword) {
            let password=var_login.newpassword;

            if (password.length >= 8) {
                set_validatepassword_length(true);
            } else {
                set_validatepassword_length(false);
            }

            if (/[a-z]/.test(password)) {
                set_validatepassword_lowercase(true);
            } else {
                set_validatepassword_lowercase(false);
            }

            if (/[A-Z]/.test(password)) {
                set_validatepassword_uppercase(true);
            } else {
                set_validatepassword_uppercase(false);
            }

            if (/[0-9]/.test(password)) {
                set_validatepassword_number(true);
            } else {
                set_validatepassword_number(false);
            }

            if (/[!@#$%^&*()_\-[\]{};':"\\|,.<>/?]/.test(password)) {
                set_validatepassword_specialcharacter(true);
            } else {
                set_validatepassword_specialcharacter(false);
            }
        }else{
            set_validatepassword_length(false);
            set_validatepassword_lowercase(false);
            set_validatepassword_uppercase(false);
            set_validatepassword_number(false);
            set_validatepassword_specialcharacter(false);
        }
    }

    function onChange_login_input(event){
        const login = { ...var_login };
        login[event.target.name] = event.target.value;
        set_login(login);
    }

    function onClick_submit(){
        submit_password();
    }

    function onClick_backtologin(){
        history.push('/login')
    }

    function onClick_togglePassword() {
        set_passwordShown(var_passwordShown ? false : true);
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {var_requestresult !== 'FAIL' &&
            <>
                <Form>
                    <Form.Group>
                        <Form.Field
                            className='newpassword'
                            id='newpassword'
                            name='newpassword'
                            autoComplete='new-password'
                            value={var_login.newpassword || ''}
                            control={Input}
                            type={!var_passwordShown ? 'password' : 'text'}
                            onChange={onChange_login_input}
                            label={t('NEW PASSWORD')}
                            placeholder={t('New Password...')}
                            icon={<Icon className="password_icon" name={!var_passwordShown ? 'eye' : 'eye slash'} link onClick={() => onClick_togglePassword()} size="large" />}
                        />
                    </Form.Group>
                    <div className="passwordvalidation">
                        <div className="passwordvalidation_item">
                            {var_validatepassword_length ?
                                <div className="passwordvalidation_item_icon"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('checkmark status')}/></div>
                                :
                                <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('fail icon')}/></div>
                            }
                            <div className="passwordvalidation_item_text">{t('8 characters long')}</div>
                        </div>
                        <div className="passwordvalidation_item">
                            {var_validatepassword_uppercase ?
                                <div className="passwordvalidation_item_icon"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('checkmark status')}/></div>
                                :
                                <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('fail icon')}/></div>
                            }
                            <div className="passwordvalidation_item_text">{t('upper case')}</div>
                        </div>
                        <div className="passwordvalidation_item">
                            {var_validatepassword_lowercase ?
                                <div className="passwordvalidation_item_icon"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('checkmark status')}/></div>
                                :
                                <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('fail icon')}/></div>
                            }
                            <div className="passwordvalidation_item_text">{t('lower case')}</div>
                        </div>
                        <div className="passwordvalidation_item">
                            {var_validatepassword_number ?
                                <div className="passwordvalidation_item_icon"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('checkmark status')}/></div>
                                :
                                <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('fail icon')}/></div>
                            }
                            <div className="passwordvalidation_item_text">{t('number')}</div>
                        </div>
                        <div className="passwordvalidation_item">
                            {var_validatepassword_specialcharacter ?
                                <div className="passwordvalidation_item_icon"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (549E17).svg" alt={t('checkmark status')}/></div>
                                :
                                <div className="passwordvalidation_item_icon passwordvalidation_item_icon_fail"><img
                                    src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (DB2430).svg" alt={t('fail icon')}/></div>
                            }
                            <div className="passwordvalidation_item_text">{t('special character')}</div>
                        </div>
                    </div>
                </Form>

                {!var_processing ?
                    <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} disabled={!var_ready} onClick={() => onClick_submit()}>{t('RESET PASSWORD')}</Button>
                    :
                    <Button loading className="btn_primary btn_active">{t('RESET PASSWORD')}</Button>
                }

            </>
            }

            <div className="btn_backtologin" onClick={() => onClick_backtologin()}>{t('Back to login')}</div>
        </>
    )

}