import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import config from './config';
import App from './App';
import './i18n';

import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';

Amplify.configure({
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "abcrc-admin-api",
                endpoint: config.apiGateway_abcrc.URL,
                region: config.apiGateway_abcrc.REGION
            },
            {
                name: "abcrc-app-api",
                endpoint: config.apiGateway_abcrc_app.URL,
                region: config.apiGateway_abcrc_app.REGION
            },
            {
                name: "translation",
                endpoint: config.apiGateway_translation.URL,
                region: config.apiGateway_translation.REGION
            },
        ]
    }
});


ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);
