import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import React from "react";
import Moment from "moment";
import datelib from './libs/date-lib';
import Routes from "./routes";
import { useTranslation } from 'react-i18next';
import "./i18n";

import 'semantic-ui-css/semantic.min.css'
import './App.css';
import config from './config';



function App() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [var_systemstatus, set_systemstatus ] = useState([]);
    const [var_time, set_time] = useState(Moment().format('LT'));
    const [var_date, set_date] = useState(Moment().format('ll'));

    const [var_visibleframe, set_visibleframe] = useState(false);
    const [var_menu, set_menu] = useState([]);
    const [var_visiblemenu, set_visiblemenu] = useState('INDIVIDUAL');
    const [var_activemenu, set_activemenu] = useState('');

    const [var_username, set_username] = useState('');
    const [var_orgname, set_orgname] = useState('');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        const var_interval = setInterval(() => {
            set_time(Moment().format('LT'));
            set_date(Moment().format('ll'));
        }, 60000);
        return () => clearInterval(var_interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (window.location.pathname.indexOf('saml') >= 0) {
            //  do nothing (SAML handles authentication)
        } else {
            if (!sessionStorage.getItem('authentication')) {
                if (window.location.pathname !== '/reset' && window.location.pathname !== '/invite') {
                    history.push("/login");
                }
            } else {
                authenticatepageload();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    useEffect(() => {
        if (sessionStorage.getItem('authentication')) {
            const var_interval = setInterval(() => {
                if (Math.floor(Date.now() / 1000) - localStorage.getItem('activetime') > 1800) {
                    handleLogout();
                }
            }, 60000);
            return () => clearInterval(var_interval);
        } else {
            const browser_language = window.navigator.language.split('-')[0];
            if (browser_language === "en" || browser_language === "fr") {
                i18n.changeLanguage(config.language[browser_language].i18n);
            } else {
                i18n.changeLanguage(config.language.en.i18n);
            }
        }
    }, [sessionStorage.getItem('authentication')]);


    useEffect(() => {
        if (!sessionStorage.getItem('authentication')) {
            get_systemstatus();
        } else {
            if (sessionStorage.getItem('redirect') === null || sessionStorage.getItem('redirect') === 'false') {
                authenticatepageload();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function get_systemstatus(){
        try {
            let results = await API_get_systemstatus();
            set_systemstatus(results[0]);
            return(results);
        } catch (e) {
            console.log(e);
        }
    }

    async function authenticatepageload() {
        try {
            //  check cognito authentication
            let AWSuser = await Auth.currentUserInfo();
            set_username(AWSuser.username);
            //  get TerraHub user details
            let result = await API_get_authentication();

            //check if account has been archived
            if (result === 'NO ACTIVE ACCOUNT'){
                alert('NO ACTIVE ACCOUNT');
            }

            result = result[0];

            let authentication = {};
            authentication['authenticated'] = true;
            authentication['id'] = result.id;
            authentication['individual_type'] = result.individual_type;
            authentication['actingorganization'] = result.organization;
            authentication['actingorganization_id'] = result.organization_id;
            authentication['actingpermission_id'] = result.permission_id;
            authentication['cognito_id'] = AWSuser.username;
            authentication['firstname'] = result.firstname;
            authentication['lastname'] = result.lastname;
            authentication['userid'] = result.userid;
            authentication['email'] = result.email;
            authentication['organization'] = result.organization;
            authentication['organization_id'] = result.organization_id;
            authentication['permission_id'] = result.permission_id;
            authentication['org_host'] = result.org_host;
            authentication['language'] = 'en';
            authentication['access'] = result.access;
            set_menu(result.menu);

            let permission = (result.organization_id && result.organization_id !== '') ? await API_get_auth_permission(result.organization_id) : {admin: false};
            if (!permission.admin) {
                set_orgname('');
            } else {
                set_orgname(result.organization);
            }

            sessionStorage.setItem('authentication', JSON.stringify(authentication));

        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_systemstatus() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-systemstatus?tz=' + datelib.querystring_timezone);
    }

    function API_get_authentication() {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-authentication');
    }

    function API_get_auth_permission(organization_id) {
        localStorage.setItem('activetime', Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-auth-permission/' + organization_id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_sidemenu(varSideMenu) {
        set_activemenu(varSideMenu);
        history.push(varSideMenu)
    }

    function set_appDefaultLanguage(default_language) {
        if (default_language === "en" || default_language === "fr") {
            if (default_language === "fr") {
                const locales = require('moment/locale/' + config.language[default_language].moment);
                Moment.updateLocale(config.language[default_language].moment, locales);
            } else {
                Moment.locale(config.language[default_language].moment);
            }
            set_time(Moment().format('LT'));
            set_date(Moment().format('ll'));
            i18n.changeLanguage(config.language[default_language].i18n);
        }
    }

    async function handleLogout() {
        await Auth.signOut();
        sessionStorage.clear();
        history.push("/login");
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {var_systemstatus && var_systemstatus.systemstatus === 'OFFLINE' ?
                <div className="login_wrapper">
                    <div className="crd_systemalert">
                        <div className='crd_systemalert_text'>{var_systemstatus.outagemessage}</div>
                        <div className='crd_systemalert_timeframe'>{var_systemstatus.outagewindow}</div>
                    </div>
                </div>
                :
                <>
                {var_systemstatus && var_systemstatus.systemstatus === 'PENDING_OFFLINE' && var_systemstatus.preoutagemessage != '' && var_systemstatus.preoutagemessage != null &&
                    <div className='systemalert'>{var_systemstatus.preoutagemessage} {var_systemstatus.outagewindow}</div>
                }
                {sessionStorage.getItem('authentication') ?
                    <>
                        {var_visibleframe ?
                            <div className="app-container">
                                <div className="app-topmenu">
                                    <div className="app-topmenu-logo"><img
                                        src={JSON.parse(sessionStorage.getItem('authentication')).logo ? JSON.parse(sessionStorage.getItem('authentication')).logo : '/assets/logo.png'}
                                        alt={t('Organization logo')} />
                                    </div>
                                    <div className="app-topmenu-menu">
                                    </div>

                                    <div className="app-topmenu-UTC">
                                        <div className="app-topmenu-UTC-icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/clock 60px (8a8d8c).svg" alt={t('clock icon')} /></div>
                                        <div className="app-topmenu-UTC-time">{var_time}</div>
                                        <div className="app-topmenu-UTC-date">{var_date}</div>
                                    </div>

                                    <div className="app-topmenu-loggedin">
                                        <div className="app-topmenu-loggedin-icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/individual 60px (8a8d8c).svg" alt={t('individual icon')} /></div>
                                        <div className="app-topmenu-loggedin-username">{JSON.parse(sessionStorage.getItem('authentication')).firstname} {JSON.parse(sessionStorage.getItem('authentication')).lastname}</div>
                                        <div className="app-topmenu-loggedin-logout" onClick={() => handleLogout()}>{t('Logout')} </div>
                                    </div>
                                </div>

                                <div className="app-content">
                                    <div className="app-sidenav">
                                        {render_sidenav()}
                                    </div>

                                    <div className="app-sidecontent">
                                        <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage }} />
                                    </div>
                                </div>

                            </div>
                            :
                            <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage }} />
                        }
                    </>
                    :
                    <Routes appProps={{ set_visibleframe, set_visiblemenu, set_activemenu, set_appDefaultLanguage}} />
                }
                </>
            }
        </>
    );

    function render_sidenav(){
        /*if (var_menu.length === 0){
            return null;
        }*/
        if (var_visiblemenu === 'INDIVIDUAL'){
            return render_sidenavitems();
        }
        return (
            <div id='app-sidenav-group_organization'>
                {render_sidenavitems()}
            </div>
        )
    }

    function render_sidenavitems(){
        return (
            <div className='app-sidenav-group'>
                <div className='app-sidenav-header'>
                    {'OVERVIEW'}
                </div>
                <div
                    className={'app-sidenav-item '+(var_activemenu === '/individuals' ? 'active' : '')}
                    onClick={() => onClick_sidemenu('/individuals')}
                >
                    <div className={"app-sidenav-item_icon " + (var_activemenu === '/individuals' ? 'active' : '')}><img src={"https://public-resources-icons.s3.amazonaws.com/icons/individuals 60px (8a8d8c).svg"} alt={t('individuals icon')} /></div>
                    <div className="app-sidenav-item_text">{t('Individuals')}</div>
                </div>
                <div 
                    className={'app-sidenav-item '+(var_activemenu === '/transactions' ? 'active' : '')}
                    onClick={() => onClick_sidemenu('/transactions')}
                >
                    <div className={"app-sidenav-item_icon " + (var_activemenu === '/transactions' ? 'active' : '')}><img src={"https://public-resources-icons.s3.amazonaws.com/icons/history 60px (717473).svg"} alt={t('individuals icon')} /></div>
                    <div className="app-sidenav-item_text">{t('Transactions')}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);