/* eslint-disable import/no-anonymous-default-export */
import { Button, Form, Input, Icon } from 'semantic-ui-react';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';

import './mdl_inviteindividual.css';



export default function({set_mdl_inviteindividual_open, populate_individuals }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_inviteindividual, set_inviteindividual ] = useState([]);
    const [ var_checkinviteindividual_exists, set_checkinviteindividual_exists ] = useState(false);
    const [ var_ready, set_ready] = useState(false);
    const [var_processing, set_processing] = useState(false);
    const [var_validationerror, set_validationerror] = useState(false);
    const [var_validationerrormessage, set_validationerrormessage] = useState('');
    const [ var_individualtype, set_individualtype ] = useState('USER');
    const [var_passwordShown, set_passwordShown] = useState(false);



    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = 'USER';
        inviteindividual['permission_id'] = '5C6A5862-9352-4F68-9AAE-D468BF7D0D34';
        inviteindividual['invitedby'] = JSON.parse(sessionStorage.getItem('authentication')).firstname + ' ' + JSON.parse(sessionStorage.getItem('authentication')).lastname;
        inviteindividual['invitedbyemail'] = JSON.parse(sessionStorage.getItem('authentication')).email;
        inviteindividual['organization'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization;
        inviteindividual['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
        inviteindividual['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        inviteindividual['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_inviteindividual(inviteindividual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        let var_validation = true;

        if ((!var_inviteindividual.firstname || var_inviteindividual.firstname === '') && (!var_inviteindividual.lastname || var_inviteindividual.lastname === '') && (!var_inviteindividual.email || var_inviteindividual.email === '') && (!var_inviteindividual.userid || var_inviteindividual.userid === '')) {
            var_validation = false;
        }
        set_ready(var_validation);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_inviteindividual]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function checkinviteindividual(){
        set_checkinviteindividual_exists(false);
        set_processing(true);

        try {
            let result = await API_post_checkinviteindividual();
            if(result[0]['records'] > 0){
                set_checkinviteindividual_exists(true);
                set_ready(false);
                set_processing(false);
            }else{
                invite_individual();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function invite_individual(){
        try {
            let inviteresult = await API_post_inviteindividual();
            if(inviteresult[0] === 'FAILED'){
                alert(t("There was a problem creating the invite.  Please log out and log back into the system and try again."))
            }else{
                set_ready(false);
                set_processing(false);
                set_mdl_inviteindividual_open(false);
                populate_individuals();
            }
        } catch (e) {
            alert(t("There was a problem creating the invite.  Please log out and log back into the system and try again."))
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_checkinviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-checkinviteindividual/'+ JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id, { body: var_inviteindividual });
    }

    function API_post_inviteindividual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-indv-invite', { body: var_inviteindividual });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_type(e, { value }){
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual['individual_type'] = value;
        inviteindividual['permission_id'] = value === 'USER' ? '5C6A5862-9352-4F68-9AAE-D468BF7D0D34' : '8BC291D7-C763-441C-B488-45935BFAD1D2';
        set_individualtype(value);
        set_inviteindividual(inviteindividual);
    }

    function onClick_togglePassword() {
        set_passwordShown(var_passwordShown ? false : true);
    }

    function onChange_invite_input(event){
        if(event.target.name==='email'){
            event.target.value=event.target.value.toLowerCase().trim()
        }
        set_validationerror(false);
        set_ready(true);
        const inviteindividual = { ...var_inviteindividual };
        inviteindividual[event.target.name] = event.target.value.trimStart();
        set_inviteindividual(inviteindividual);
    }

    function onClick_submit(){
        if (var_ready) {
            let var_validation = false;
            let var_validmessage = '';
            if (!var_inviteindividual.firstname || var_inviteindividual.firstname === '') {
                var_validation = true;
                var_validmessage = 'Please provide a first name';
            } else if (!var_inviteindividual.lastname || var_inviteindividual.lastname === '') {
                var_validation = true;
                var_validmessage = 'Please provide a last name';
            } else if (!var_inviteindividual.userid || var_inviteindividual.userid === '') {
                var_validation = true;
                var_validmessage = 'Please provide a user id';
            } else {
                if (var_inviteindividual.individual_type === 'ADMIN') {

                    if (!var_inviteindividual.email || var_inviteindividual.email === '') {
                        var_validation = true;
                        var_validmessage = 'Please provide an email address';
                    }
                    else {
                        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\+[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*)|([\w-]+(?:\.[\w-]+)*)([\w-]+(?:\+[\w-]+)*)([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                        if (!pattern.test(var_inviteindividual.email)) {
                            var_validation = true;
                            var_validmessage = 'Please provide a valid email address';
                        }
                    }
                } else {
                    if (!var_inviteindividual.usercode || var_inviteindividual.usercode === '') {
                        var_validation = true;
                        var_validmessage = 'Please provide an user code';
                    } else if (!var_inviteindividual.accesscode || var_inviteindividual.accesscode === '') {
                        var_validation = true;
                        var_validmessage = 'Please provide an access code';
                    } else if (var_inviteindividual.accesscode.length < 6) {
                        var_validation = true;
                        var_validmessage = 'Access code must be at least 6 characters long';
                    }
                }
            }
            set_validationerrormessage(var_validmessage);
            set_validationerror(var_validation);
            if (var_validation === false) {
                checkinviteindividual();
            }
        }
    }
    


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Invite Individual")}</div>
            <div className="modal-content">
                {var_checkinviteindividual_exists &&
                <div className="message warning">
                    <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t("warning icon")}/></div>
                    <div className="message_text resolution">
                        {t('An individual with this userid, usercode, or email address already exists.')}
                    </div>
                </div>
                }
                <Form>
                    <Form.Group>
                        <Form.Select
                            className='individual_type'
                            name='individual_type'
                            label={t("INVITE TYPE")}
                            options={[  {text: 'USER', value: 'USER'},
                                {text: 'ADMIN', value: 'ADMIN'}]}
                            value={var_inviteindividual.individual_type || ''}
                            onChange={onChange_type}
                            placeholder={t("Select an invite type...")}
                        />
                    </Form.Group>
                    {var_individualtype === 'USER' &&
                    <Form.Group>
                        <Form.Field
                            id='usercode'
                            name='usercode'
                            value={var_inviteindividual.usercode || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            label={t("USER CODE")}
                            placeholder={t("User code...")}
                        />
                        <Form.Field
                            id='accesscode'
                            name='accesscode'
                            value={var_inviteindividual.accesscode || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            type={!var_passwordShown ? 'password' : 'text'}
                            label={t("ACCESS CODE")}
                            placeholder={t("Access code...")}
                            icon={<Icon className="password_icon" name={!var_passwordShown ? 'eye' : 'eye slash'} link onClick={() => onClick_togglePassword()} size="large" />}
                        />
                    </Form.Group>
                    }
                    <Form.Field
                        id='userid'
                        name='userid'
                        value={var_inviteindividual.userid || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("USER ID")}
                        placeholder={t("User ID...")}
                    />
                    {var_individualtype === 'ADMIN' &&
                    <Form.Field
                        id='email'
                        name='email'
                        value={var_inviteindividual.email || ''}
                        onChange={onChange_invite_input}
                        control={Input}
                        label={t("EMAIL")}
                        placeholder={t("Email...")}
                    />
                    }
                    <Form.Group className="mdl_inviteindividual_individual">
                        <Form.Field
                            id='firstname'
                            name='firstname'
                            value={var_inviteindividual.firstname || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            label={t("FIRST NAME")}
                            placeholder={t("First name...")}
                        />
                        <Form.Field
                            id='lastname'
                            name='lastname'
                            value={var_inviteindividual.lastname || ''}
                            onChange={onChange_invite_input}
                            control={Input}
                            label={t("LAST NAME")}
                            placeholder={t("Last name...")}
                        />
                    </Form.Group>
                </Form>
                {var_validationerror &&
                    <div className="message warning">
                        <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')} /></div>
                        <div className="message_text_wrapper">
                            <div className="message_text cause">{t(var_validationerrormessage)}</div>
                        </div>
                    </div>
                }
            </div>
            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_inviteindividual_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className={"btn_primary " + (var_ready ? ' btn_active' : '')} onClick={() => onClick_submit()}>{t("INVITE INDIVIDUAL")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("INVITE INDIVIDUAL")}</Button>
                    }

                </div>
            </div>
        </>
    )
}
