/* eslint-disable import/no-anonymous-default-export */
import { Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_confirmreinvite.css';



export default function({set_mdl_confirmreinvite_open, populate_individual, var_individual, set_individual, var_individual_id, populate_indv}){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_processing, set_processing] = useState(false);
    


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const individual = { ...var_individual };
        individual['individual_id'] = var_individual_id;
        individual['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        individual['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_individual(individual);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function onClick_resendinvite(){
        set_processing(true);
        try {
            await API_post_resendinvite();
            populate_individual();
            set_processing(false);
            set_mdl_confirmreinvite_open(false);
        } catch (e) {
            console.log(e);
        }
    }

    async function onClick_resendpassword(){
        set_processing(true);
        try {
            await API_post_passwordreset();
            populate_individual();
            set_mdl_confirmreinvite_open(false);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_resendinvite(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-indv-resendinvite/'+var_individual.organizationindividual_id);
    }

    function API_post_passwordreset(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.post('abcrc-admin-api', '/post-passwordreset', { body: var_individual });
    }



    //  event functions ------------------------------------------------------------------------------------------------



    // RENDER APP ======================================================================================================

    return (
        <>
            {['INVITED', 'DECLINED', 'INACTIVE', 'AUTHENTICATING', 'INVITEEXPIRED'].includes(var_individual.invitestatus) &&
            <div className="modal-header">{t("Resend Invite")}</div>
            }
            {var_individual.invitestatus === 'ACTIVE' &&
            <div className="modal-header">{t("Send Password Reset Link")}</div>
            }

            <div className="modal-content">
                {['INVITED', 'DECLINED', 'INACTIVE', 'AUTHENTICATING', 'INVITEEXPIRED'].includes(var_individual.invitestatus) &&
                <p>{t("Are you sure want to resend this invite?")}</p>
                }
                {var_individual.invitestatus === 'ACTIVE' &&
                    <p>{t("Are you sure want to send a new password reset link?")}</p>
                }
            </div>

            <div className="modal-footer">
                {['INVITED', 'DECLINED', 'INACTIVE', 'AUTHENTICATING', 'INVITEEXPIRED'].includes(var_individual.invitestatus) &&
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_confirmreinvite_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className="btn_primary btn_active" onClick={() => onClick_resendinvite()}>{t("RESEND INVITE")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("RESEND INVITE")}</Button>
                    }
                </div>
                }
                {var_individual.invitestatus === 'ACTIVE' &&
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_confirmreinvite_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className="btn_primary btn_active" onClick={() => onClick_resendpassword()}>{t("SEND PASSWORD LINK")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("SEND PASSWORD LINK")}</Button>
                    }
                </div>
                }
            </div>
        </>
    )
}