/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import './crd_resetfail.css';



export default function(){

    //  variable declarations ------------------------------------------------------------------------------------------

    const history = useHistory();
    const { t } = useTranslation();


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------


    
    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_backtologin(){
        history.push('/login')
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className="message warning">
                <div className="message_icon"><img src="https://public-resources-icons.s3.amazonaws.com/icons/warning 60px (d91e18).svg" alt={t('warning icon')}/></div>
                <div className="message_text_wrapper">
                    <div className="message_text cause">{t('We were not able to reset your password at this time.')}</div>
                </div>
            </div>

            <div className="btn_backtologin" onClick={() => onClick_backtologin()}>{t('Back to login')}</div>

        </>
    )

}