/* eslint-disable import/no-anonymous-default-export */
import { API } from "aws-amplify";
import { Button, Modal } from 'semantic-ui-react'
import { useEffect, useState } from "react";
import React from "react";
import "../../../../libs/auth-lib";

import './tab_details.css';
import MDL_CONFIRMATION from "../../../../components/cmp_confirmation/cmp_confirmation";
import MDL_CONFIRMREINVITE from "./mdl_confirmreinvite/mdl_confirmreinvite";
import MDL_CONFIRMREVOKE from "./mdl_confirmrevoke/mdl_confirmrevoke";
import auth from "../../../../libs/auth-lib";
import { useTranslation } from 'react-i18next';
import '../../../../i18n';


export default function({ set_mdl_open, var_activetab, var_individual_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_individual, set_individual] = useState([]);

    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_mdl_confirmreinvite_open, set_mdl_confirmreinvite_open ] = useState(false);
    const [ var_mdl_confirmrevoke_open, set_mdl_confirmrevoke_open ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(()=>{
        if(var_activetab === 'DETAILS') {
            populate_individual();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual(){
        try {
            let individual = await API_get_individual();
            individual[0]['individual_id'] = var_individual_id;
            individual[0]['organization_id'] = JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id;
            individual[0]['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
            individual[0]['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
            set_individual(individual[0]);
        } catch (e) {
            console.log(e);
        }
    }

    async function archive_individual(){
        try {
            if(var_individual.invitestatus !== 'ARCHIVE'){
                await API_put_indv_archive('ARCHIVE');
            }else{
                await API_put_indv_archive('ACTIVE');
            }
            populate_individual();
            populate_function();
        } catch (e) {
            console.log(e);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.get('abcrc-admin-api', '/get-individual/'+ JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id + '/' + var_individual_id);
    }

    function API_put_indv_archive(var_archive){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('abcrc-admin-api', '/put-indv-archive/' + var_individual.organizationindividual_id + '/' + var_archive, { body: var_individual });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_archive(){
        set_mdl_confirmation_open(true);
    }

    function onClick_unarchive(){
        archive_individual();
    }


    function onClick_resendinvite(){
        set_mdl_confirmreinvite_open(true);
    }

    function onClick_revokeinvite(){
        set_mdl_confirmrevoke_open(true);
    }

    // RENDER APP ======================================================================================================

    return (
        <div className="modal-tab">

            {var_individual.invitestatus === 'ARCHIVE' &&
            <div className="modal-archive" onClick={() => onClick_unarchive()}>
                <div className="modal-archive-icon"><img className="btn_icon" src="https://public-resources-icons.s3.amazonaws.com/icons/archive 30px (ffffff).svg" alt={t("archive icon")}/></div>
                <div className="modal-archive-text">{t("This individual has been archived. Click this bar to make them active again.")}
                </div>
            </div>
            }

            <div id="tab_details">
                <div className="profile">
                    <div className="content-card-content">
                        <div className="detail-item">
                            <div className="detail-item-label">{t("NAME")}</div>
                            <div className="detail-item-text">{var_individual.firstname} {var_individual.lastname}</div>
                        </div>
                        <div className="detail-spacer"></div>
                        {var_individual.individual_type === 'ADMIN' &&
                            <div className="detail-item">
                                <div className="detail-item-label">{t("EMAIL")}</div>
                                <div className="detail-item-text">{var_individual.email}</div>
                            </div>
                        }
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("INDIVIDUAL TYPE")}</div>
                            <div className="detail-item-text">{var_individual.individual_type}</div>
                        </div>
                        {var_individual.individual_type === 'USER' &&
                            <>
                            <div className="detail-spacer"></div>
                            <div className="detail-item">
                                <div className="detail-item-label">{t("USER CODE")}</div>
                                <div className="detail-item-text">{var_individual.usercode}</div>
                            </div>
                            </>
                        }
                        <div className="detail-spacer"></div>
                        <div className="detail-item">
                            <div className="detail-item-label">{t("USER ID")}</div>
                            <div className="detail-item-text">{var_individual.userid}</div>
                        </div>
                    </div>
                </div>
                <div className="options">
                    <div className="statuswrapper">
                        <div className="statusprogress">
                            {var_individual.individual_type === 'ADMIN' &&
                                <div className="statusprogress-item">
                                    <div className="statusprogress-item-text">{t("INVITED")}</div>
                                    <div className={"statusprogress-item-bar invited "  + var_individual.invitestatus}>
                                        <img src="https://public-resources-icons.s3.amazonaws.com/icons/envelope 60px (ffffff).svg" alt={t("envelope icon")}/>
                                    </div>
                                </div>
                            }
                            {var_individual.individual_type === 'ADMIN' &&
                                <div className="statusprogress-item">
                                    <div className="statusprogress-item-text">{t("AUTHENTICATING")}</div>
                                    <div className={"statusprogress-item-bar authenticating "  + var_individual.invitestatus}>
                                        <img src="https://public-resources-icons.s3.amazonaws.com/icons/lock 60px (ffffff).svg" alt={t("lock icon")}/>
                                    </div>
                                </div>
                            }
                            {var_individual.invitestatus === 'INVITEEXPIRED' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("INVITE EXPIRED")}</div>
                                <div className={"statusprogress-item-bar inviteexpired " + var_individual.invitestatus}>
                                    <img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (ffffff).svg" alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                            {var_individual.invitestatus === 'DECLINED' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("DECLINED")}</div>
                                <div className={"statusprogress-item-bar declined " + var_individual.invitestatus}>
                                    <img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (ffffff).svg" alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                            {var_individual.invitestatus !== 'DECLINED' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("ACTIVE")}</div>
                                <div className={"statusprogress-item-bar" + (var_individual.individual_type === 'ADMIN' ? ' active ' : ' active-user ') + var_individual.invitestatus}>
                                    <img src="https://public-resources-icons.s3.amazonaws.com/icons/checkmark 60px (ffffff).svg" alt={t("checkmark icon")}/>
                                </div>
                            </div>
                            }
                            {var_individual.invitestatus === 'INACTIVE' &&
                            <div className="statusprogress-item">
                                <div className="statusprogress-item-text">{t("INACTIVE")}</div>
                                <div className={"statusprogress-item-bar inactive " + var_individual.invitestatus}>
                                    <img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (ffffff).svg" alt={t("x icon")}/>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="statusoptions">

                            {((var_individual.invitestatus === 'INVITED' || var_individual.invitestatus === 'AUTHENTICATING' || var_individual.invitestatus === 'INVITEEXPIRED') && var_individual.individual_type === 'ADMIN') &&
                                <Button className="btn_tertiary" onClick={() => onClick_resendinvite()}><img className="btn_icon" src="https://public-resources-icons.s3.amazonaws.com/icons/envelope 60px (4dcbd4).svg" alt={t("envelope icon")}></img>{t("RESEND INVITE")}</Button>
                            }
                            {(var_individual.invitestatus === 'ACTIVE' && var_individual.individual_type === 'ADMIN') &&
                                <Button className="btn_tertiary" onClick={() => onClick_resendinvite()}><img className="btn_icon" src="https://public-resources-icons.s3.amazonaws.com/icons/lock 60px (4dcbd4).svg" alt={t("lock icon")}></img>{t("SEND PASSWORD RESET LINK")}</Button>
                            }

                            {(var_individual.invitestatus !== 'INACTIVE' && var_individual.invitestatus !== 'DECLINED' && var_individual.invitestatus !== 'ACTIVE' && var_individual.individual_type === 'ADMIN') &&
                                <Button className="btn_tertiary" onClick={() => onClick_revokeinvite()}><img className="btn_icon" src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (4dcbd4).svg" alt={t("x icon")}></img>{t("REVOKE INVITE")}</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {(JSON.parse(sessionStorage.getItem('authentication')).permission_id === '8BC291D7-C763-441C-B488-45935BFAD1D2') &&
            <div className="modal-footer">

                {var_individual.invitestatus !== 'ARCHIVE' &&
                (JSON.parse(sessionStorage.getItem('authentication')).permission_id === '8BC291D7-C763-441C-B488-45935BFAD1D2') &&
                <div className="modal-footer-archive">
                    <Button className="btn_tertiary" onClick={() => onClick_archive()}><img className="btn_icon" src="https://public-resources-icons.s3.amazonaws.com/icons/archive 30px (4dcbd4).svg" alt={t("archive icon")}/>{t("ARCHIVE INDIVIDUAL")}</Button>
                </div>
                }
            </div>
            }


            {/***** MODAL: CONFIRMATION *******************************************************************************/}

            <Modal id="mdl_confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation_open}
                   onClose={() => set_mdl_confirmation_open(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open={set_mdl_confirmation_open}
                    var_modaltitle={t("Archive Individual")}
                    var_message={t("Are you sure you want to archive this individual?")}
                    confirmation_function={archive_individual}>
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: CONFIRMATION **************************************************************************/}



            {/***** MODAL: REINVITE CONFIRMATION *****************************************************************/}

            <Modal id="mdl_org_indv_details_confirmreinvite"
                   dimmer={'inverted'}
                   open={var_mdl_confirmreinvite_open}
                   onClose={() => set_mdl_confirmreinvite_open(false)}>
                <MDL_CONFIRMREINVITE
                    set_mdl_confirmreinvite_open={set_mdl_confirmreinvite_open}
                    var_individual_id={var_individual_id}
                    var_individual={var_individual}
                    set_individual={set_individual}
                    populate_individual={populate_individual}
                >
                </MDL_CONFIRMREINVITE>
            </Modal>

            {/***** END MODAL: REINVITE CONFIRMATION *************************************************************/}



            {/***** MODAL: REVOKE CONFIRMATION *******************************************************************/}

            <Modal id="mdl_org_indv_details_confirmrevoke"
                   dimmer={'inverted'}
                   open={var_mdl_confirmrevoke_open}
                   onClose={() => set_mdl_confirmrevoke_open(false)}>
                <MDL_CONFIRMREVOKE
                    set_mdl_confirmrevoke_open={set_mdl_confirmrevoke_open}
                    var_individual={var_individual}
                    set_individual={set_individual}
                    populate_function={populate_function}
                    set_mdl_open={set_mdl_open}
                >
                </MDL_CONFIRMREVOKE>
            </Modal>

            {/***** END MODAL: REVOKE CONFIRMATION ***************************************************************/}

        </div>
    )

};
