/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

import TAB_DETAILS from "./tab_details/tab_details";

import './mdl_indv.css';



export default function({ set_mdl_open, var_individual_id, populate_function }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_activetab, set_activetab] = useState('DETAILS');


    //  variable listeners ---------------------------------------------------------------------------------------------



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_tab){
        set_activetab(var_tab);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-title">{t("Individual Details")}</div>
                <div className="modal-header-tabs">
                    <div className={"modal-header-tabs-item"  + (var_activetab === 'DETAILS' ? ' active' : '')} onClick={() => onClick_tab(`DETAILS`)}>
                        {t("Details")}
                    </div>
                </div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}><img src="https://public-resources-icons.s3.amazonaws.com/icons/x 60px (717473).svg" alt={t("x icon")} /></div>
            </div>
            <div className="modal-tabs">
                <div className={(var_activetab !== 'DETAILS' ? 'modal-tab-hide' : '')}>
                    <TAB_DETAILS
                        set_mdl_open={set_mdl_open}
                        var_activetab={var_activetab}
                        var_individual_id={var_individual_id}
                        populate_function={populate_function}>
                    </TAB_DETAILS>
                </div>
            </div>
        </>
    )
}