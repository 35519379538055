/* eslint-disable import/no-anonymous-default-export */
import { Button } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import React from "react";
import { useTranslation } from 'react-i18next';
import '../../../../../i18n';

import './mdl_confirmrevoke.css';



export default function({set_mdl_confirmrevoke_open, var_individual, set_individual, populate_function, set_mdl_open }){

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation();

    const [ var_processing, set_processing] = useState(false);
    


    //  variable listeners ---------------------------------------------------------------------------------------------

    useEffect(() => {
        const individual = { ...var_individual };
        individual['transactionby'] = JSON.parse(sessionStorage.getItem('authentication')).id;
        individual['transactionorg'] = JSON.parse(sessionStorage.getItem('authentication')).organization_id;
        set_individual(individual);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------

    async function update_inviterevoke(){
        set_processing(true);
        try {
            await API_put_inviterevoke();
            set_mdl_confirmrevoke_open(false);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
        populate_function();
        set_mdl_open(false);   
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_inviterevoke(){
        localStorage.setItem('activetime',Math.floor(Date.now() / 1000));
        return API.put('abcrc-admin-api', '/put-inviterevoke/' + JSON.parse(sessionStorage.getItem('authentication')).actingorganization_id, { body: var_individual });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_submit(){
        update_inviterevoke();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            <div className="modal-header">{t("Revoke Invite")}</div>

            <div className="modal-content">
                <p>{t("Are you sure want to revoke this invitation?")}</p>
            </div>

            <div className="modal-footer">
                <div className="modal-footer-buttons">
                    <Button className="btn_secondary" onClick={() => set_mdl_confirmrevoke_open(false)}>{t("CANCEL")}</Button>
                    {!var_processing ?
                        <Button className="btn_primary btn_active" onClick={() => onClick_submit()}>{t("REVOKE INVITE")}</Button>
                        :
                        <Button loading className="btn_primary btn_active">{t("REVOKE INVITE")}</Button>
                    }
                </div>
            </div>
        </>
    )
}