import React from "react";
import { Route, Switch } from "react-router-dom";
import login from "./containers/login/login";
import invite from "./containers/invite/invite";
import notfound from "./containers/notfound/notfound";
import AppliedRoute from "./components/applied_route";

import individuals from "./containers/individuals/individuals";
import transactions from "./containers/transactions/transactions";
import reset from "./containers/reset/reset";

    export default function Routes({ appProps }) {
        return (
            <Switch>
                <AppliedRoute path="/" exact component={individuals} appProps={appProps} />
                <AppliedRoute path="/individuals" exact component={individuals} appProps={appProps} />
                <AppliedRoute path="/transactions" exact component={transactions} appProps={appProps} />
                <AppliedRoute path="/login" exact component={login} appProps={appProps} />
                <AppliedRoute path="/login/:code" exact component={login} appProps={appProps} />
                <AppliedRoute path="/invite" exact component={invite} appProps={appProps} />
                <AppliedRoute path="/reset" exact component={reset} appProps={appProps} />
                <Route component={notfound} />
            </Switch>
        );
    }
